import 'mapbox.js';

const mapEl = document.getElementById('map');

if (mapEl) {
  const lat = mapEl.getAttribute('data-lat');
  const long = mapEl.getAttribute('data-long');

  L.mapbox.accessToken =
    'pk.eyJ1Ijoidm9qdGVjaGtsb3NjcnMiLCJhIjoiY2t1aDJ2aG84MmFkNzJ1bW96OHhjZjI5MCJ9.XmcBluFo4lSK0fQMZiqeLw';

  const map = L.mapbox.map('map', undefined, {
    dragging: !L.Browser.mobile,
  });

  map.setView([lat, long], 14);
  map.addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/outdoors-v11'));

  L.marker([lat, long], {
    icon: L.mapbox.marker.icon({
      'marker-size': 'large',
      'marker-symbol': 'campsite',
      'marker-color': '#A1D102',
      'text-color': '#fff',
    }),
  }).addTo(map);
}
